import {
    PRODUCT_GET_LIST,
    PRODUCT_GET_LIST_SUCCESS,
	PRODUCT_GET_LIST_ERROR,

} from '../actions';
const INIT_STATE = {
	data: [],
	loading:true
};

const productList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case PRODUCT_GET_LIST: // GEL LIST 
			return { ...state, loading: true };
		case PRODUCT_GET_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.products,  
                // page: action.response.page,
                // totalPage:action.response.totalPage,
                totalItemCount:action.response.totalItemCount
            };
		case PRODUCT_GET_LIST_ERROR:
			return { ...state, loading: false };
		default: return state 
	}
}
export default productList
