import {
    USERS_GET_LIST,
    USERS_GET_LIST_SUCCESS,
	USERS_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	data: [],
	loading:true,
	getData:false
};

const UserList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case USERS_GET_LIST: // GEL LIST 
			return { ...state, loading: true };
		case USERS_GET_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
				getData:true,
                data: action.response.users,  
                page: action.response.page,
                totalPage:action.response.totalPage,
                totalItemCount:action.response.totalItemCount
            };
		case USERS_GET_LIST_ERROR:
			return { ...state, loading: false };
		default: return  state;
	}
}
export default UserList