import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';


import company from '@iso/redux/company/myCompanyReducer';
import auth from '@iso/redux/auth/authReducer';

import companyList from '@iso/redux/companies/companyListReducer';
import companyDetail from '@iso/redux/companies/companyDetailReducer';
import userList from '@iso/redux/users/userListReducer';
import userDetail from '@iso/redux/users/userDetailReducer';
import productList from '@iso/redux/productList/productListReducer';
import productDetail from '@iso/redux/productDetails/productDetailReducers';

import modelList from '@iso/redux/modelList/modelListReducers';
import modelDetails from '@iso/redux/modelDetails/modelDetailReducers';

import materials from '@iso/redux/materials/materialsReducers';
import materialDetails from '@iso/redux/materialDetails/materialDetailReducers';

import stockList from '@iso/redux/stockLists/stockListReducers';
import stockDetails from '@iso/redux/stockDetails/stockDetailReducers';

import orderItemList from '@iso/redux/orderItemList/orderItemListReducers';
import orderItemDetail from '@iso/redux/orderItemDetail/orderItemReducers';
import taskList from '@iso/redux/taskLists/tasklistReducer';

import taskDetails from '@iso/redux/taskDetails/taskDetailReducers';
import componentList from '@iso/redux/componentList/componentListReducers';

import componentDetails from '@iso/redux/componentDetails/componentDetailsReducer';

import deliveriedList from '@iso/redux/deliveryList/deliveriedListReducers';
import deliveryDetails from '@iso/redux/deliveryDetails/deliveriedDetailReducers';

import imageList from '@iso/redux/imageList/imageListReducers';
import imageDetails from '@iso/redux/imageDetails/imageDetailReducers';

import tcmbList from '@iso/redux/tcmbList/tcmbListReducers';
import tcmb from '@iso/redux/tcmb/tcmbReducers';

import offerList from '@iso/redux/offerList/offerListReducer';
import offerDetails from '@iso/redux/offerDetails/offerDetailReducers';

import logList from '@iso/redux/logList/logListReducer';
import todoList from '@iso/redux/todoList/todoListReducers';

import productStockList from '@iso/redux/productStockList/productStockListReducers';
import productstockDetails from '@iso/redux/productStockDetails/productStockDetailReducers';



import Mails from '@iso/redux/mail/reducer';
import Calendar from '@iso/redux/calendar/reducer';
import Box from '@iso/redux/box/reducer';
import Notes from '@iso/redux/notes/reducer';
import Todos from '@iso/redux/todos/reducer';
import Contacts from '@iso/redux/contacts/reducer';
import Cards from '@iso/redux/card/reducer';
import Chat from '@iso/redux/chat/reducers';
import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Invoices from '@iso/redux/invoice/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import YoutubeSearch from '@iso/redux/youtubeSearch/reducers';
import Articles from '@iso/redux/articles/reducers';
import Investors from '@iso/redux/investors/reducers';
import scrumBoard from '@iso/redux/scrumBoard/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';
import profile from '@iso/redux/profile/reducer';
import githubSearch from '@iso/redux/githubSearch/reducers';
import quiz from '@iso/redux/quiz/reducer';

export default combineReducers({
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  company,
  auth,
  offerList,
  offerDetails,
  companyList,
  companyDetail,
  userList,
  userDetail,
  productList,
  productDetail,

  modelList,
  modelDetails,
  materials,
  materialDetails,

  stockList,
  stockDetails,
  productStockList,
  productstockDetails,

  orderItemList,
  orderItemDetail,
  taskList,
  taskDetails,
  componentList,
  componentDetails,
  deliveriedList,
  deliveryDetails,
  imageList,
  imageDetails,
  tcmbList,
  tcmb,
  logList,
  todoList,

  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  Articles,
  Investors,
  scrumBoard,
  modal,
  drawer,
  profile,
  githubSearch,
  quiz,
});
