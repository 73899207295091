import {
	MATERIAL_DETAILS_ACTION,
	MATERIAL_DETAILS_SUCCESS,
    MATERIAL_DETAILS_ERROR,
	CREATE_MATERIAL_ACTION,
	CREATE_MATERIAL_SUCCESS,
	CREATE_MATERIAL_ERROR,
	UPDATE_MATERIAL_ACTION,
	UPDATE_MATERIAL_SUCCESS,
	UPDATE_MATERIAL_FAILURE,
	DELETE_MATERIAL_ACTION,
	DELETE_MATERIAL_SUCCESS,
	DELETE_MATERIAL_FAILURE
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:false
};

const materialDetails =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case MATERIAL_DETAILS_ACTION: //GET MATERIAL DETAILS
			return { ...state, loading: true };
		case MATERIAL_DETAILS_SUCCESS:
            return { ...state,
                 loading: false,  
                 data: action.response};
		case MATERIAL_DETAILS_ERROR:
            return { ...state, loading: false };	
            	
		case CREATE_MATERIAL_ACTION: //CREATE MATERIAL
			return { ...state, loading: true };
		case CREATE_MATERIAL_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_MATERIAL_ERROR:
			return { ...state, loading: false ,status: action.response};	
		case UPDATE_MATERIAL_ACTION: //UPDATE MATERIAL
			return { ...state, loading: true };
		case UPDATE_MATERIAL_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_MATERIAL_FAILURE:
			return { ...state, loading: false ,status: action.response};
		case DELETE_MATERIAL_ACTION: //DELETE MATERIAL
			return { ...state, loading: true };
		case DELETE_MATERIAL_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_MATERIAL_FAILURE:
			return { ...state, loading: false };
		default: return state;
	}
}
export default materialDetails