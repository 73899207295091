import {
	ORDERITEM_DETAILS_ACTION,
	ORDERITEM_DETAILS_SUCCESS,
	ORDERITEM_DETAILS_ERROR,
	UPDATE_ORDERITEM_ACTION,
	UPDATE_ORDERITEM_SUCCESS,
	UPDATE_ORDERITEM_FAILURE,
	CREATE_ORDERITEM_ACTION,
	CREATE_ORDERITEM_SUCCESS,
	CREATE_ORDERITEM_FAILURE,
	DELETE_ORDERITEM_ACTION,
	DELETE_ORDERITEM_SUCCESS,
	DELETE_ORDERITEM_FAILURE,
	ORDERITEM_ACTION,
	ORDERITEM_ACTION_SUCCESS,
	ORDERITEM_ACTION_FAILURE,

} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/authActions';
// Get - OrderItemDetails  ****************************************************************************************************************************************************
export const orderitem_details_pending = () => ({
	type: ORDERITEM_DETAILS_ACTION
});

export const orderitem_details_success = (res) => ({
	type: ORDERITEM_DETAILS_SUCCESS,
	response: res
});

export const orderitem_details_failure = () => ({
	type: ORDERITEM_DETAILS_ERROR
});

export const getOrderItemDetails = (id) => dispatch =>{
	const url = `/ordersv3/item/${id.id}`
	dispatch(orderitem_details_pending());
	
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				dispatch(orderitem_details_success(res.data));
                resolve(true);
            } else {
                dispatch(orderitem_details_failure());
                reject();
            }
        })
        .catch((err) => {
            dispatch(orderitem_details_failure());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
            reject(err);
        });
    });
};

// update ORrderITEM ****************************************************************************************************************************************************
export const update_orderitem_pending = (orderID) => ({
	type: UPDATE_ORDERITEM_ACTION,
	orderID: orderID
});

export const update_orderitem_successful = (res) => ({
	type: UPDATE_ORDERITEM_SUCCESS,
	response: res
});

export const update_orderitem_failed = (response) => ({
	type: UPDATE_ORDERITEM_FAILURE,
	response:response
});

export const updateOrderItem = (data) => dispatch => {
	const url = `/ordersv3/updateitem/${data._id}`
	
	dispatch(update_orderitem_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_orderitem_successful(res.data));
				resolve(true);
			} else {
                dispatch(update_orderitem_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_orderitem_failed(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){

			}
			reject(err.response); 
		});
	});
}
// create orderItem ****************************************************************************************************************************************************

export const create_orderitem_pending = () => ({
	type: CREATE_ORDERITEM_ACTION
});

export const create_orderitem_success = (res) => ({
	type: CREATE_ORDERITEM_SUCCESS,
	response: res
});

export const create_orderitem_failure = (response) => ({
	type: CREATE_ORDERITEM_FAILURE,
	response: response
});

export const createOrderItem = (data) => dispatch => {
    const url = `/ordersv3/create/`
	dispatch(create_orderitem_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_orderitem_success(res.data));
				resolve(true);
			} else {
				dispatch(create_orderitem_failure());
				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_orderitem_failure(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err);
		});
	});
}

// delete OFFER ****************************************************************************************************************************************************
export const delete_offeritem_pending = () => ({
	type: DELETE_ORDERITEM_ACTION,
});

export const delete_offeritem_successful = (res) => ({
	type: DELETE_ORDERITEM_SUCCESS,
	response:res
	
});

export const delete_offeritem_failed = (res) => ({
	type: DELETE_ORDERITEM_FAILURE,
	response:res
});

export const deleteOfferItem = (data) => dispatch => {
	const url = `/ordersv3/delete/`
	dispatch(delete_offeritem_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + url, data,
		{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_offeritem_successful(res.data));
				resolve(true);
			} else {
				dispatch(delete_offeritem_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_offeritem_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}


//ACTION TASK List GET ****************************************************************************************************************************************************

export const orderitem_action_pending = () => ({
	type: ORDERITEM_ACTION
});

export const orderitem_action_successful = (res) => ({
	type: ORDERITEM_ACTION_SUCCESS,
	response: res
});

export const orderitem_action_failed = () => ({
	type: ORDERITEM_ACTION_FAILURE
});

export const orderItemActions = (command,data) => dispatch =>{
	const apiUrl = `/ordersv3/actionlist`;
	dispatch(orderitem_action_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + `${apiUrl}?command=${command}`, data,
		{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(orderitem_action_successful(res));
				resolve(true);
			} else { 
				dispatch(orderitem_action_failed());
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(orderitem_action_failed());
			reject(err);
			if (err.response && err.response.status && err.response.status === 401) {
				
			}
		});
	});
}