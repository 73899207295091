import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ResetPassword/ResetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import('@iso/containers/Authentication/Auth0/Auth0Callback')
    ),
  },
  {
    path: PUBLIC_ROUTE.SHOP,
    component: lazy(() =>
      import('@iso/containers/Ecommerce/Algolia/InstantSearch')
    ),
  },
  {
    path: PUBLIC_ROUTE.OFFERREPORT,
    component: lazy(() =>
      import('@iso/reports/offerReport')
    ),
  },
  {
    path: PUBLIC_ROUTE.ORDERWORKORDER,
    component: lazy(() =>
      import('@iso/reports/OrderWorkOrders')
    ),
  },
  {
    path: PUBLIC_ROUTE.WORKORDER,
    component: lazy(() =>
      import('@iso/reports/orderList-QR')
    ),
  },
  {
    path: PUBLIC_ROUTE.DATASHEET,
    component: lazy(() =>
      import('@iso/reports/productDatasheet')
    ),
  },
  {
    path: PUBLIC_ROUTE.PRODUCTLIST,
    component: lazy(() =>
      import('@iso/reports/pricelist')
    ),
  },
  {
    path: PUBLIC_ROUTE.WEB,
    component: lazy(() =>
      import('@iso/containers/web/webDashBoard/Dashboard')
    ),
  },
  {
    path: PUBLIC_ROUTE.WEBPRODUCTDETAILS,
    component: lazy(() =>
      import('@iso/containers/web/products/ProductDetail')
    ),
  },
];



function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.auth);
  //const auth = useSelector((state) => state.auth);
  //console.log(auth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
      auth.status === "successful" && auth.role < 3 ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
