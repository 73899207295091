import {
	PRODUCT_DETAILS_ACTION,
	PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_ERROR,
	CREATE_PRODUCT_ACTION,
	CREATE_PRODUCT_SUCCESS,
	CREATE_PRODUCT_ERROR,
	UPDATE_PRODUCT_ACTION,
	UPDATE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT_FAILURE,
	DELETE_PRODUCT_ACTION,
	DELETE_PRODUCT_SUCCESS,
	DELETE_PRODUCT_FAILURE
} from '../actions';
import axios from 'axios';
import {store} from '../store';
import { user_reauth_request } from '../auth/authActions';
// Get - Offer Details ****************************************************************************************************************************************************
export const product_details_pending = () => ({
	type: PRODUCT_DETAILS_ACTION
});

export const product_details_success = (res) => ({
	type: PRODUCT_DETAILS_SUCCESS,
	response: res
});

export const product_details_failure = () => ({
	type: PRODUCT_DETAILS_ERROR
});

export const getProductDetails = (id) => dispatch =>{
	const url = `/products/${id.id}`
	dispatch(product_details_pending());
    return new Promise((resolve, reject) => { 
        axios.get(process.env.REACT_APP_SERVER_URL + url,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
				dispatch(product_details_success(res.data)) 
                resolve(true);
            } else {
				dispatch(product_details_failure());
                reject();
            }
        })
        .catch((err) => {
            dispatch(product_details_failure());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
            reject(err);
        });
    });
};

// POST - Offer Create ****************************************************************************************************************************************************
export const create_product_pending = () => ({
	type: CREATE_PRODUCT_ACTION
});

export const create_product_success = (res) => ({
	type: CREATE_PRODUCT_SUCCESS,
	response: res
});

export const create_product_failure = (response) => ({
	type: CREATE_PRODUCT_ERROR,
	response:response
});

export const createProduct = (data,history) => dispatch => {
    const url = `/products/create/`
	dispatch(create_product_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_product_success(res.data));
				if(history){
					history.push({
						pathname : "/dashboard/products/" + res.data._id
					})
				}
				resolve(true);
			} else {
				dispatch(create_product_failure());
				reject(res);
			};
		})
		.catch((err) => {
            dispatch(create_product_failure(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err);
		});
	});
}
// update Offer ****************************************************************************************************************************************************
export const update_product_pending = (id) => ({
	type: UPDATE_PRODUCT_ACTION,
	productID: id
});

export const update_product_successful = (res) => ({
	type: UPDATE_PRODUCT_SUCCESS,
	response: res
});
 
export const update_product_failed = (response) => ({
	type: UPDATE_PRODUCT_FAILURE,
	response:response
});

export const updateProduct = (data) => dispatch => {
	const url = `/products/update/${data._id}`
	dispatch(update_product_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_product_successful(res.data));
				resolve(true);
			} else {
				dispatch(update_product_failed());
				reject(res);
			}
		})
		.catch((err) => {
            dispatch(update_product_failed(err.response));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}else if (err.response.status === 404){

			}
			reject(err.response);
		});
	});
}

// delete Model ****************************************************************************************************************************************************
export const delete_product_pending = () => ({
	type: DELETE_PRODUCT_ACTION,
});

export const delete_product_successful = (res) => ({
	type: DELETE_PRODUCT_SUCCESS,
	response:res
	
});

export const delete_product_failed = (res) => ({
	type: DELETE_PRODUCT_FAILURE,
	response:res
});

export const deleteProduct = (id) => dispatch => {
	const url = `/products/delete/${id}`

	dispatch(delete_product_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + url,{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(delete_product_successful(res.data));        
				resolve(true);
			} else {
				dispatch(delete_product_failed());
				reject(res);
			};
		})
		.catch((err) => {
			dispatch(delete_product_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
