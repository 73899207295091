import {
    COMPANY_ACTION,
    COMPANY_SUCCESS,
    COMPANY_ERROR,
    UPDATE_COMPANY_ACTION,
    UPDATE_COMPANY_SUCCESS,
	UPDATE_COMPANY_FAILURE,
	CREATE_COMPANY0_ACTION,
	CREATE_COMPANY0_SUCCESS,
	CREATE_COMPANY0_FAILURE,
} from '../actions';

const INIT_STATE = {
	data:{},
	loading:false,
};

const myCompany =  (state = INIT_STATE, action) => {
	switch (action.type) {

		case COMPANY_ACTION: // GET DETAILS 
			return {...state, loading: true};
		case COMPANY_SUCCESS:
			return { ...state, loading: false,data: action.response };
		case COMPANY_ERROR:
			return { ...state, loading: false, data:{}, error: action.payload };

		case UPDATE_COMPANY_ACTION: //UPDATE
			return { ...state,loading: true};
		case UPDATE_COMPANY_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_COMPANY_FAILURE:
			return { ...state, loading: false,status: action.response };
		
		case CREATE_COMPANY0_ACTION: //CREATE COMPANY
			return { ...state,loading: true};
		case CREATE_COMPANY0_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_COMPANY0_FAILURE:
			return { ...state, loading: false,status: action.response}; 
		default:  {
			return state
		}
	}
}
export default myCompany