import {
	TASK_DETAILS_ACTION,
	TASK_DETAILS_SUCCESS,
    TASK_DETAILS_ERROR,
	CREATE_TASK_ACTION,
	CREATE_TASK_SUCCESS,
	CREATE_TASK_ERROR,
	UPDATE_TASK_ACTION,
	UPDATE_TASK_SUCCESS,
	UPDATE_TASK_FAILURE,
	DELETE_TASK_ACTION,
	DELETE_TASK_SUCCESS,
	DELETE_TASK_FAILURE,
} from '../actions';

const INIT_STATE = {
	data: {},
	status:"",
	loading:false
};

const  taskDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case TASK_DETAILS_ACTION: //GET TASK DETAILS
			return { ...state, loading: true };
		case TASK_DETAILS_SUCCESS:
            return { ...state,
                 loading: false,  
                 data: action.response};
		case TASK_DETAILS_ERROR:
            return { ...state, loading: false };	
            	
		case CREATE_TASK_ACTION: //CREATE TASK
			return { ...state, loading: true };
		case CREATE_TASK_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_TASK_ERROR:
			return { ...state, loading: false,data:{},status:action.response };	
		case UPDATE_TASK_ACTION: //UPDATE TASK
			return { ...state, loading: true };
		case UPDATE_TASK_SUCCESS: 
			return { ...state, loading: false, data: action.response };
		case UPDATE_TASK_FAILURE:
			return { ...state, loading: false,status:action.response }; 
		case DELETE_TASK_ACTION: //DELETE TASK
			return { ...state, loading: true };
		case DELETE_TASK_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_TASK_FAILURE:
			return { ...state, loading: false };
		default: return state;
	}
}
export default taskDetails