import {
    COMPANY_ACTION,
    COMPANY_SUCCESS,
    COMPANY_ERROR,
    UPDATE_COMPANY_ACTION,
    UPDATE_COMPANY_SUCCESS,
	UPDATE_COMPANY_FAILURE,
	CREATE_COMPANY0_ACTION,
	CREATE_COMPANY0_SUCCESS,
	CREATE_COMPANY0_FAILURE,
} from '../actions';
import {store} from '../store';
import axios from 'axios';
import { user_reauth_request } from '../auth/authActions';
// Get - Company Details ****************************************************************************************************************************************************
export const company_pending = () => ({
	type: COMPANY_ACTION
});

export const company_success = (res) => ({
	type: COMPANY_SUCCESS,
	response: res
});

export const company_failure = (response) => ({
	type: COMPANY_ERROR,
	response:response
});

export const getCompany = (id,params) => dispatch =>{
	let url =""
	if(params && params.companyid && params.token){
		 url = `/company/${id.id}/${params.id}/${params.companyid}/${params.token}`
	}else{
		 url = `/company/${id.id}`
	}
	
    dispatch(company_pending());
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_SERVER_URL + url,	
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
        .then(res => {
            if (res.status === 200) {
                dispatch(company_success(res.data));
                resolve(true);
            } else {
                dispatch(company_failure(res.data));
                reject();
            }
        })
        .catch((err) => {
            dispatch(company_failure(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }
            reject(err);
        });
    });
};


// update CompanyDetails ****************************************************************************************************************************************************
export const update_company_pending = (companyId) => ({
	type: UPDATE_COMPANY_ACTION,
	companyId: companyId
});

export const update_company_successful = (res) => ({
	type: UPDATE_COMPANY_SUCCESS,
	response: res
});

export const update_company_failed = (response) => ({
	type: UPDATE_COMPANY_FAILURE,
	response:response
});

export const updatemyCompany = (data) => dispatch => {
    const url = `/company/update/${data._id}`
	dispatch(update_company_pending());
	return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_SERVER_URL + url, data,        
        {
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(update_company_successful(res.data));


				resolve(true);
			} else {
				dispatch(update_company_failed(res.data));
				reject(res);
			}
		})
		.catch((err) => {
			dispatch(update_company_failed(err.response));
            if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){

			}
			reject(err.response.data);
		});
	});
}

// create Company ****************************************************************************************************************************************************
export const create_company_pending = () => ({
	type: CREATE_COMPANY0_ACTION,
});

export const create_company_successful = (res) => ({
	type: CREATE_COMPANY0_SUCCESS,
	response: res
});

export const create_company_failed = () => ({
	type: CREATE_COMPANY0_FAILURE
});

export const createmyCompany = (data,history) => dispatch => {
	const url = `/company/create/`
	dispatch(create_company_pending());
	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + url, data,{
            headers: {Authorization: 'Bearer ' + store.getState().auth.token}
        })
		.then(res => {
			if (res.status === 200) {
				dispatch(create_company_successful(res.data));

				history.push({
					pathname : "/app/settings/myCompany/" + 1
				})
				resolve(true);
			} else {
				dispatch(create_company_failed());

				reject(res);
			};
		})
		.catch((err) => {
			dispatch(create_company_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
            }else if (err.response.status === 404){

			}
			reject(err);
		});
	});
}








