import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector,useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';
import { store } from './redux/store';
import { connect as wsConnect  } from '@giantmachines/redux-websocket';



export default function AppProvider({ children }) {



  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  const currentAppLocale = AppLocale[locale];
  const dispatch = useDispatch();

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {

    store.dispatch(wsConnect(String(process.env.REACT_APP_SERVER_URL).replace("https", "wss").replace("http", "ws") + "/ws"));
    // eslint-disable-next-linestore.dispatch(wsDisconnect());
  }, [dispatch]);


  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
