import {
	OFFER_DETAILS_ACTION,
	OFFER_DETAILS_SUCCESS,
	OFFER_DETAILS_ERROR,
	CREATE_OFFER_ACTION,
	CREATE_OFFER_SUCCESS,
	CREATE_OFFER_ERROR,
	UPDATE_OFFER_ACTION,
	UPDATE_OFFER_SUCCESS,
	UPDATE_OFFER_FAILURE,
	DELETE_OFFER_ACTION,
	DELETE_OFFER_SUCCESS,
	DELETE_OFFER_FAILURE,
	
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:false
};

const offerDetails =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case OFFER_DETAILS_ACTION: //GET OFFER DETAILS
			return { ...state, loading: true };
		case OFFER_DETAILS_SUCCESS:
            return { ...state,
                 loading: false,  
                 data: action.response};
		case OFFER_DETAILS_ERROR:
            return { ...state, loading: false };	   	
		case CREATE_OFFER_ACTION: //CREATE OFFER
			return { ...state, loading: true };
		case CREATE_OFFER_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_OFFER_ERROR:
			return { ...state, loading: false,status: action.response };	
		case UPDATE_OFFER_ACTION: //UPDATE OFFER
			return { ...state, loading: true };
		case UPDATE_OFFER_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_OFFER_FAILURE:
			return { ...state, loading: false ,status: action.response};
		case DELETE_OFFER_ACTION: //DELETE OFFER
			return { ...state, loading: true };
		case DELETE_OFFER_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_OFFER_FAILURE:
			return { ...state, loading: false }; 
		default: return state;
	}
}
export default offerDetails