import {
	COMPANY_DETAILS_ACTION,
	COMPANY_DETAILS_SUCCESS,
	COMPANY_DETAILS_ERROR,
	UPDATE_COMPANY_DETAILS_ACTION,
	UPDATE_COMPANY_DETAILS_SUCCESS,
	UPDATE_COMPANY_DETAILS_FAILURE,
	CREATE_COMPANY_ACTION,
	CREATE_COMPANY_SUCCESS,
	CREATE_COMPANY_FAILURE,
	DELETE_COMPANY_ACTION,
	DELETE_COMPANY_SUCCESS,
	DELETE_COMPANY_FAILURE
} from '../actions';

const INIT_STATE = {
	data:{},
	status:"",
	loading:false,

};

const companyDetails = (state = INIT_STATE, action) => { 
	switch (action.type) {

		case COMPANY_DETAILS_ACTION: // GET DETAILS 
			return {...state, loading: true};
		case COMPANY_DETAILS_SUCCESS:
			return { ...state, loading: false,data: action.response };
		case COMPANY_DETAILS_ERROR:
			return { ...state, loading: false,listloading: false, data:{}, error: action.payload };

		case UPDATE_COMPANY_DETAILS_ACTION: //UPDATE
			return { ...state,loading: true};
		case UPDATE_COMPANY_DETAILS_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_COMPANY_DETAILS_FAILURE:
			return { ...state, loading: false, status:action.response};
		case CREATE_COMPANY_ACTION: //CREATE COMPANY
			return { ...state,loading: true};
		case CREATE_COMPANY_SUCCESS:
			return { ...state, loading: false, data: action.response};
		case CREATE_COMPANY_FAILURE:
			return { ...state, loading: false,data:{},status:action.response};
			
		case DELETE_COMPANY_ACTION: //DELETE COMPANY
			return { ...state,loading: true};
		case DELETE_COMPANY_SUCCESS:
			return { ...state, loading: false, result: action.response };
		case DELETE_COMPANY_FAILURE:
			return { ...state, loading: false,error: action.response};
		default:  return state;
	}
}
export default companyDetails
