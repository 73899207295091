import {
	COMPANY_LIST_GET_LIST,
	COMPANY_LIST_GET_LIST_SUCCESS,
	COMPANY_LIST_GET_LIST_ERROR
} from '../actions';

const INIT_STATE = {
	data:[],
	loading:false,
};
const companyList =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case COMPANY_LIST_GET_LIST: // GEL LIST 
			return { ...state, loading: true };
		case COMPANY_LIST_GET_LIST_SUCCESS:
			return { ...state, 
				loading: false, 
				data: action.response.companyList,
				totalPage:action.response.totalPage,
				page:action.response.page,
				totalItemCount:action.response.totalItemCount };
		case COMPANY_LIST_GET_LIST_ERROR:
			return { 
				...state, loading: 
				false, error: "" };

		default:{
			return state
		}
	}
}

export default companyList