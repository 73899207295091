import { getDefaultPath } from '@iso/lib/helpers/url_sync';
import actions, { getView } from './actions';
import { isServer } from '@iso/lib/helpers/isServer';

const preKeys = getDefaultPath();
const initState = {
	collapsed: !isServer && window.innerWidth > 1280 ? false : true,
	view: !isServer && getView(window.innerWidth),
	height: !isServer && window.innerHeight,
	openDrawer: false,
	openKeys: preKeys,
	current: preKeys,
	offerReportType:"T0",
	productsFilter:0,
	offersFilter:0,
	orderFilter:"Tümü",
	materialFilter:'Tümü',
	modelFilter:'Tümü',
	listPerVal:30,
	priceListTextOpen:true,
	copyForm:"",
	copyValues:[],
	pricelisttext:`Değerli Müşterimiz, Ürünlerimize ait fiyat  listesi  aşağıda tarafınıza sunulmuştur. Fiyat listemiz üzerinden %XX iskonto  uygulamaktayız. Bilgilerinize sunar iyi çalışmalar dileriz.`,
	pricelisttype:0,

	categoryList : [
		{value : 0 , label : "model.kategori"+0, color:"magenta"},
		{value : 1 , label : "model.kategori"+1, color:"orange"},
		{value : 2 , label : "model.kategori"+2, color:"gold"},
		{value : 3 , label : "model.kategori"+3, color:"blue"},
		{value : 4 , label : "model.kategori"+4, color:"green"},
		{value : 5 , label : "model.kategori"+5, color:"purple"},
		{value : 6 , label : "model.kategori"+6, color:"lime"},
		{value : 7 , label : "model.kategori"+7, color:"cyan"},
		{value : 8 , label : "model.kategori"+8, color:"magenta"},
	],
	CurrencyList: [
		{ value: '₺', label: '₺' },
		{ value: '$', label: '$' },
		{ value: '€', label: '€' }
	],
	CCTList: [
		{ value: 'XXXX', label: 'XXXX' },
		{ value: '6500 K', label: '6500 K' },
		{ value: '6000 K', label: '6000 K' },
		{ value: '5000 K', label: '5000 K' },
		{ value: '4000 K', label: '4000 K' },
		{ value: '3500 K', label: '3500 K' },
		{ value: '3000 K', label: '3000 K' },
	],
	optikList: [
		{ value: 'XX', label: 'XX' },
		{ value: 'AS', label: 'AS' },
		{ value: 'ST', label: 'ST' },
		{ value: 'SP', label: 'SP' },
		{ value: '15', label: '15' },
		{ value: '30', label: '30' },
		{ value: '45', label: '45' },
		{ value: '60', label: '60' },
		{ value: '90', label: '90' },
	], 
	UnitList : [
		{ value: 'Adet', label: "genel.Adet"},
		{ value: 'Kg', label: "genel.Kg" },
		{ value: 'Metre', label: "genel.Metre"},
		{ value: 'Takım', label: "genel.Takım"},
		{ value: 'Paket', label:"genel.Paket"}
	],
	operationCenterList:[
		{value: "fatura", label: "Fatura"},
		{value: "ithalat", label: "İthalat"},
		{value: "uretim", label: "Üretim"},
		{value: "Teslimat",label :"Teslimat"},
		{value: "fire", label: "Fire"},
	],
	operationTypeSelect:[
		{ value: "giris", label: "Giriş" },
		{ value: "cikis", label: "Çıkış" }
	],
	KDVList : [
		{ value: '20', label: '20' },
		{ value: '18', label: '18' },
		{ value: '8', label: '8' },
		{ value: '0', label: '0' }
	],
	importanceList: [
		{ value: 0, label: 'task.importance0'},
		{ value: 1, label: 'task.importance1'},
		{ value: 2, label: 'task.importance2'}
	],
	statusList: [
		{ value: 0, label: 'task.status0',color:'red'},
		{ value: 1, label: 'task.status1',color:'blue'},
		{ value: 2, label: 'task.status2',color:'green'}
	],
	taskTypeList: [
		{ value: 0, label: 'task.production',color:'red'},
		{ value: 1, label: 'task.control',color:'blue'},
		{ value: 2, label: 'task.operation',color:'green'}
	],
	operationList : [
		{value : "Dizgi" ,label : "Dizgi"},
		{value : "Mekanik Montaj" ,label :"Mekanik Montaj"},
		{value : "Paketleme" ,label :"Paketleme"},
		{value : "E-Test" ,label : "E-Test"},
		{value : "Harici İşlem" ,label : "Harici İşlem"},
		{value : "Onay" ,label : "Onay"}
	],
	controlList: [
		{ value: 0, label: 'order.componentstatus0',color:'red'},
		{ value: 1, label: 'order.componentstatus1',color:'blue'},
		{ value: 2, label: 'order.componentstatus2',color:'lime'},
		{ value: 3, label: 'order.componentstatus3',color:'green'},
		{ value: 4, label: 'order.componentstatus4',color:'magenta'}
	],
	OfferStatus: [
		{value : 0 , label:"offer.OfferStatus0" },
		{value : 1 , label:"offer.OfferStatus1" },
		{value : 2 , label:"offer.OfferStatus2" },
		{value : 3 , label:"offer.OfferStatus3" },
		{value : 4 , label:"offer.OfferStatus4" }
	],
	orderStatus: [
		{value : 0 , label:"order.orderStatus0" },
		{value : 1 , label:"order.orderStatus1" },
		{value : 2 , label:"order.orderStatus2" },
		{value : 3 , label:"order.orderStatus3" },
		{value : 4 , label:"order.orderStatus4" },
		{value : 5 , label:"order.orderStatus5" },
		{value : 6 , label:"order.orderStatus6" }
	],
	BuyingStatus: [
		{value : 0 , label:"buying.buyingStatus0" },
		{value : 1 , label:"buying.buyingStatus1" },
		{value : 2 , label:"buying.buyingStatus2" },
		{value : 3 , label:"buying.buyingStatus3" },
		{value : 4 , label:"buying.buyingStatus4" },
	],
	CompanyGroup: [
		{ value: "Suppliers", label: "company.Suppliers"},
		{ value: "Customer", label: "company.Customer" },
	],
	imageDescriptionList : [
		{value : "Ana Görsel" , label : "Ana Görsel"},
		{value : "Yan Görsel" , label : "Yan Görsel"},
		{value : "Teknik Resim" , label : "Teknik Resim"},
		{value : "Renkli Resim" , label : "Renkli Resim"},
	],
	deliveryStatus :[
		{value : 0 , label:'delivery.0',color:"red"},
		{value : 1 , label:'delivery.2',color:"green"},
		{value : 2 , label:'delivery.2',color:"green"}
	],
	deliveryStatusDescription :[
		{value : 0 , label:'delivery.statusdescription0',color:"green"},
		{value : 1 , label:'delivery.statusdescription1',color:"orange"},
		{value : 2 , label:'delivery.statusdescription2',color:"red"}
	]
};

export default function appReducer(state = initState, action) {
	switch (action.type) {
		case actions.COLLPSE_CHANGE:
		return {
			...state,
			collapsed: !state.collapsed,
		};
		case actions.COLLPSE_OPEN_DRAWER:
		return {
			...state,
			openDrawer: !state.openDrawer,
		};
		case actions.TOGGLE_ALL:
		if (state.view !== action.view || action.height !== state.height) {
			const height = action.height ? action.height : state.height;
			return {
			...state,
			collapsed: action.collapsed,
			view: action.view,
			height,
			};
		}
		break;
		case actions.CHANGE_OPEN_KEYS:
		return {
			...state,
			openKeys: action.openKeys,
		};
		case actions.CHANGE_CURRENT:
		return {
			...state,
			current: action.current,
		};
		case actions.CLEAR_MENU:
		return {
			...state,
			openKeys: [],
			current: [],
		};
		case actions.SET_OFFER_REPORT_TYPE:
			return {
			...state,
			offerReportType:action.val
		}
		case actions.SET_COUNTER:
			return {
			...state,
			appCounter:action.val

		}
		case actions.SET_PRICE_LIST_PERCENT:
			return {
			...state,
			listPerVal:action.val
		}
		case actions.SET_PRICE_LIST_TEXTOPEN:
			return {
			...state,
			priceListTextOpen:action.val
		}
		case actions.SET_PRICE_LIST_TEXT:
			return {
			...state,
			pricelisttext:action.val
		}
		case actions.SET_PRICE_LIST_TYPE:
			return {
			...state,
			pricelisttype:action.val
		}
		case actions.SET_PRICE_LIST_FILTER:
			return {
			...state,
			productsFilter:action.val
		}
		case actions.SET_COPY_FORM:
			return {
			...state,
			copyForm:action.val
		}
		case actions.SET_COPY_VALUES:
			return {
			...state,
			copyValues:action.val
		}
		case actions.SET_OFFER_FILTERS:
			return {
			...state,
			offersFilter:action.val
		}
		case actions.SET_ORDER_FILTERS:
			return {
			...state,
			orderFilter:action.val
		}
		case actions.SET_MATERIAL_FILTERS:
			return {
			...state,
			materialFilter:action.val
		}
		case actions.SET_MODEL_FILTERS:
			return {
			...state,
			modelFilter:action.val
		}
		default:
		return state;
	}
	return state;
}
