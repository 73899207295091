
//import {getMaterialsList} from '@iso/redux/materials/materialsActions';
import {getOfferDetails} from '@iso/redux/offerDetails/offerDetailActions';
import {getOrderItemList} from '@iso/redux/orderItemList/orderItemListActions';
import {getOrderItemDetails} from '@iso/redux/orderItemDetail/orderItemActions';
//import {getProductList} from '@iso/redux/productList/productListActions';


export const wsMiddleware = store => next => action => {
    //const today  = new Date();
	if(action.type === "REDUX_WEBSOCKET::MESSAGE"){
        
        let msg = JSON.parse(action.payload.message);
        console.log("Message Hadled");

        if(msg && msg.collection){
			if(msg.collection==="Logs"){
                if(msg.operation==="insert"){
                    // const data = {
                    //     query:"date",
                    //     date:today
                    // }
                    //store.dispatch(getlogList(data));
                    //soundEffect.play();
                }
            }
            else if(msg.collection==="offer"){
                if(msg.operation==="change"){
                    store.dispatch(getOfferDetails({id:msg.objectId}));
                }
            }
            else if(msg.collection==="offeritem"){
                if(msg.operation==="change"){
                    store.dispatch(getOrderItemDetails({id:msg.objectId}));
                }else if(msg.operation==="insert"){
                    const filter = store.getState().App.orderFilter
                    if(filter==="Tümü"){
                        store.dispatch(getOrderItemList("list","date",1,4));
                        
                    }else{
                        store.dispatch(getOrderItemList("list","date",filter,filter));
                    }
                }
            }

            else if(msg.collection==="Materials"){
                console.log("Materials List  Güncelleniyor..")
                //store.dispatch(getMaterialsList("list"))
                //store.dispatch(getStockOutList(today))

            }else if(msg.collection==="Tasks"){
                if(msg.operation==="insert"){
                    
                    //store.dispatch(getTasksList())
                }else if(msg.operation==="update"){
                   // store.dispatch(getTasksList())
  
                }
            }else if(msg.collection==="Product"){
                if(msg.operation==="update"){
                    //store.dispatch(getProductList("list","model_category"))
                }else if(msg.operation==="insert"){
                   // store.dispatch(getTasksList())
                }
            }else if(msg.collection==="Model"){
                if(msg.operation==="update"){
                    //store.dispatch(getModelList("detail","model_kategori"))
                }else if(msg.operation==="insert"){
                   // store.dispatch(getTasksList())
                }
            }else{
                return
            }

			
		}

    }
    return next(action)
}