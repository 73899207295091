import {
    COMPONENT_GET_LIST,
    COMPONENT_GET_LIST_SUCCESS,
	COMPONENT_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	data: [],
	loading:false
};

const componentList =(state = INIT_STATE, action) => {
	switch (action.type) {
		case COMPONENT_GET_LIST: // GEL LIST 
			return { ...state, loading: true };
		case COMPONENT_GET_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.componentlist
            };
		case COMPONENT_GET_LIST_ERROR:
			return { ...state, loading: false };
		default: {
			return state
		}
	}
}
export default componentList