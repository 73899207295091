import {
    TASK_GET_LIST,
    TASK_GET_LIST_SUCCESS,
	TASK_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	data: [],
	loading:false
};

const taskList =(state = INIT_STATE, action) => {
	switch (action.type) {
		case TASK_GET_LIST: // GEL LIST 
			return { ...state, loading: true };
		case TASK_GET_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.tasklist
            };
		case TASK_GET_LIST_ERROR:
			return { ...state, loading: false };
		default: {
			return state
		}
	}
}
export default taskList